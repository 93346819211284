<template>
  <v-expansion-panels flat tile accordion>
    <v-expansion-panel data-cy="filter_form">
      <v-expansion-panel-header class="font-weight-bold text"
        >Filter</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-row class="px-6">
          <!-- workflow filter -->
          <v-col cols="6">
            <v-select
              :disabled="$route.name != 'jobs'"
              dense
              class="workflow-filter mt-2"
              :items="workflows"
              attach
              chips
              label="Workflows"
              multiple
              @input="adjustFilter([$event, 'workflow'])"
            ></v-select>
          </v-col>
          <!-- structures filter -->
          <v-col cols="6">
            <v-select
              class="structures-filter mt-2"
              dense
              :items="structures"
              attach
              chips
              label="Structure Types"
              multiple
              @input="adjustFilter([$event, 'structure'])"
            ></v-select>
          </v-col>
          <!-- job state filter -->
          <v-col cols="6">
            <v-select
              class="states-filter mt-2"
              dense
              :items="jobStates"
              attach
              chips
              label="States"
              multiple
              @input="adjustFilter([$event, 'state'])"
            ></v-select>
          </v-col>
          <!-- data filter -->
          <v-col cols="6">
            <v-form ref="form">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-left="300"
                transition="scale-transition"
                translation-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="dates-filter"
                    :value="dateRangeText"
                    label="Date Range"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="clearDates"
                    append-icon="mdi-close"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="datesRange"
                  range
                  @input="adjustFilter([$event, 'date'])"
                ></v-date-picker>
              </v-menu>
            </v-form>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      datesRange: [],
      //Todo: this should be dynamic
      applications: ['Molecule', 'Open Systems', 'Periodic Systems'],
      jobStates: ['ACTIVE', 'COMPLETED', 'FAILED', 'EXTERNALLY_TERMINATED'],
      workflows: [
        'abinitio_pyscf_vibrations',
        'abinitio_pyscf_energy',
        'abinitio_pyscf_geoopt',
        'abinitio_pyscf_hubbard',
        'bath_mapper',
        'noise_app'
      ],
      dateMenu: false,
    }
  },

  methods: {

    ...mapMutations('backend', ['setFilter', 'resetDateFilter']),
    ...mapActions('backend', ['load']),

    clearDates() {
      this.$refs.form.reset()
      this.datesRange = []
      this.resetDateFilter()
    },

    adjustFilter([value, type]) {
      this.setFilter([value, type])
    },

  },

  computed: {

    ...mapState('backend', {
        schemas: (state) => state.schemas
    }),

    structures(){
        return this.schemas.filter(e => e.tags.includes('structure')).map(e => e = e.label)
    },

    dateRangeText() {
      return this.datesRange.join(' ~ ')
    },
  },

  async created() {
    this.setFilter([[], 'structure'])
    this.setFilter([[], 'state'])
    this.setFilter([[], 'date'])
    this.setFilter([[], 'workflow'])
    if(this.schemas.length === 0){
        await this.load(['schema'])
    }
  },
}
</script>

<style scoped>
.v-expansion-panel-header {
  padding-right: 95% !important;
  padding-left: 40px !important;
}
</style>